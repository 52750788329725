<template>
  <div id="app">
    <template v-if="currentWave == 0">
        <h1>Drink Battle Game</h1>
        <label for="drinkers">Number of drinkers:</label>
        <input type="number" v-model="drinkers" min="1" max="100" />
        <button class="btn" @click="startGame">Start Game</button>
    </template>
    <BattleScene
      v-else
      :wave-number="currentWave"
      :round-number="currentRound"
      :drinkers="drinkers"
      :drinks="drinks"
      :alc-increment="alcIncrement"
      @round-complete="nextRound"
      @wave-complete="nextWave"
    />
  </div>
</template>

<script>
import BattleScene from "./components/BattleScene.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    BattleScene,
  },
  data() {
    return {
      drinks: [],
      currentWave: 0,
      currentRound: 0,
      drinkers: 1,
      waveCount: 5,
      alcIncrement: 2,
    };
  },
  methods: {
    async fetchDrinks() {
      const response = await axios.get(
        "https://api.orderbilly.com/api/events/a3124d46-cf55-454e-961e-0823185c8c44/categorygroups"
      );

      const products = [];
      for (const category of response.data.data[0].categories) {
        if (category.id <= 0) continue;
        for (const product of category.products) {
          if (!product.is_available) continue;
          products.push({
            id: product.id,
            name: product.name,
            price: product.price,
            description: product.description,
            alc: this.getAlcPercentage(product),
            category: {
              id: category.id,
              name: category.name,
            },
            health: 100,
            image: "https://nois.beer/assets/bottle-1024.png",
          });
        }
      }
      this.drinks = products;
    },
    getAlcPercentage(drink) {
      const descParts = drink.description.split(" ");
      const nameParts = drink.name.split(" ");
      for (const part of [...descParts, ...nameParts]) {
        if (part.endsWith("%")) {
          return parseFloat(part.replace(",", "."));
        }
      }
      return 0;
    },
    startGame() {
      this.currentWave = 1;
      this.currentRound = 1;
      this.nextRound();
    },
    nextRound() {
      if (this.currentRound < 2) {
        this.currentRound++;
      } else {
        this.currentRound = 1;
        this.nextWave();
      }
    },
    nextWave() {
      this.currentWave++;
      if (this.currentWave > this.waveCount) {
        alert("Game Over! You've completed all the waves.");
        this.currentWave = 0;
        this.currentRound = 0;
        return;
      }
      this.nextRound();
    },
  },
  mounted() {
    this.fetchDrinks();
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  /* disable double tap zoom */
  touch-action: manipulation;
}
body {
  font-family: "Press Start 2P", cursive;
  background-color: #f2f2f2;
  text-align: center;
  color: #333333;
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 2px solid #0288d1;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
h1 {
  color: #81d4fa;
  margin: 20px 0;
  font-size: 2em;
}
.btn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #0288d1;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 20px;
}
.btn:hover {
  background-color: #01579b;
}
label {
  display: block;
  margin: 10px 0;
}
input {
  padding: 5px;
  font-size: 16px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #0288d1;
  background-color: #f2f2f2;
  color: black;
}
</style>
