<template>
  <div class="battle-scene">
    <img src="@/assets/img/grass_background.png" class="background" />
    <div class="enemy-area">
      <EnemyDisplay
        :drink="currentDrink"
        :drinkers="drinkers"
        @drink-clicked="decreaseHealth"
      />
    </div>
    <div class="player-area">
      <img src="@/assets/img/charmander_back.png" class="player" />
    </div>
    <div class="your-info-container">
      <img src="@/assets/img/your_info.png" class="your-info" />
      <div class="your-stats">
        <div class="your-name">Kunniezupn</div>
        <div class="your-level">Lvl 5</div>
        <div class="health-bar">
          <div
            class="health"
            style="width: 100%"
            :class="{ green: true }"
          ></div>
        </div>
      </div>
    </div>
    <div class="command-menu">
      <div class="command" @click="tackle">TACKLE</div>
      <div class="command">ITEM</div>
      <div class="command">RUN</div>
    </div>
    <div class="enemy-info-container">
      <img src="@/assets/img/enemy_info.png" class="enemy-info" />
      <div class="enemy-stats">
        <div class="enemy-name">{{ currentDrink.name }}</div>
        <div class="enemy-level">Lvl {{ currentDrink.alc }}</div>
        <div class="health-bar">
          <div
            class="health"
            :style="{ width: currentDrink.health + '%' }"
            :class="{
              green: currentDrink.health > 50,
              orange: currentDrink.health <= 50 && currentDrink.health > 20,
              red: currentDrink.health <= 20,
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EnemyDisplay from "./EnemyDisplay.vue";

export default {
  name: "BattleScene",
  components: {
    EnemyDisplay,
  },
  props: {
    waveNumber: Number,
    roundNumber: Number,
    drinkers: Number,
    drinks: Array,
    alcIncrement: Number,
  },
  data() {
    return {
      currentDrink: {
        name: "",
        alc: 0,
        health: 100,
        image: "",
      },
    };
  },
  methods: {
    initializeRound() {
      const drink = this.getRandomDrink();
      drink.health = 100;
      this.currentDrink = drink;
    },
    getRandomDrink() {
      let filteredDrinks = this.drinks.filter(
        (drink) =>
          drink.alc >= this.waveNumber * this.alcIncrement || drink.alc === 0
      );

      // select a random drink, with highest chance it being an alcoholic drink
      if (Math.random() < 0.8) {
        filteredDrinks = filteredDrinks.filter((drink) => drink.alc > 0);
      }

      return filteredDrinks[Math.floor(Math.random() * filteredDrinks.length)];
    },
    decreaseHealth() {
      this.currentDrink.health = Math.max(
        0,
        this.currentDrink.health - 100 / this.drinkers
      );
      if (this.currentDrink.health === 0) {
        this.$emit("round-complete");
        this.initializeRound();
      }
    },
    tackle() {
      this.decreaseHealth();
    },
  },
  mounted() {
    this.initializeRound();
  },
};
</script>

<style scoped>
.battle-scene {
  position: absolute;
  /* center */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* aspect ration should always be 100∶59 */
  max-height: 100vh;
  max-width: calc(100vh * 100 / 59);
  height: calc(100% - 32px);

  background-color: #f8f8f8;
  border: 2px solid #000;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.background {
  position: relative;
  top: 0;
  left: 0;
  height: calc(100% - 80px);
  z-index: 1;
  object-fit: cover;
}
.enemy-area {
  position: absolute;
  right: 0;
  top: 0;
  width: 54%;
  height: 100%;
  padding-bottom: 80px;
  z-index: 2;
}
.player-area {
  position: absolute;
  bottom: 80px;
  left: 0;
  width: 54%;
  z-index: 2;
}
.player {
  width: 40%;
}
.command-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #f8f8f8;
  border-top: 2px solid #000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 3;
}
.command {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #000;
  padding: 10px;
}
.your-info-container {
  position: absolute;
  bottom: 88px;
  right: 3%;
  z-index: 2;
  width: 40%;
}
.your-info {
  width: 100%;
}
.your-stats {
  left: 0;
  top: 0;
  position: absolute;
  padding: 5%;
  margin-left: 7%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.your-name,
.your-level {
  white-space: nowrap;
  font-size: 6px;
  font-weight: bold;
  margin-bottom: 4px;
}
.your-stats .health-bar {
  width: 47.3%;
  height: 7.1%;
  position: absolute;
  left: 17.3%;
  bottom: 27.5%;
}

.enemy-info-container {
  position: absolute;
  top: 18px;
  left: 3%;
  z-index: 2;
  display: flex;
  align-items: center;
  width: 40%;
}
.enemy-info {
  width: 100%;
}
.enemy-stats {
  position: absolute;
  padding: 5%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.enemy-name,
.enemy-level {
  white-space: nowrap;
  font-size: 6px;
  font-weight: bold;
  margin-bottom: 4px;
}
.health-bar {
  width: 47.3%;
  height: 7.1%;
  position: absolute;
  right: 11.3%;
  bottom: 27.5%;
}
.health {
  height: 100%;
  border-radius: 1px;
  background-color: #d32f2f;
}
.health.green {
  background-color: #4caf50;
}
.health.orange {
  background-color: #ff9800;
}
.health.red {
  background-color: #f44336;
}
</style>
