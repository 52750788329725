<template>
  <div class="enemy" @click="drinkClicked">
    <img :src="drink.image" class="enemy-image" />
  </div>
</template>

<script>
export default {
  name: "EnemyDisplay",
  props: {
    drink: Object,
    drinkers: Number,
  },
  methods: {
    drinkClicked() {
      this.$emit("drink-clicked");
    },
  },
};
</script>

<style scoped>
.enemy {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12%;
  position: relative;
  cursor: pointer;
  height: 55%;
}
.enemy-image {
  height: 100%;
}
</style>
